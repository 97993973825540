import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TariffOptionModel } from '../../activation-store/store/models/tariff-data.model';

@Injectable({
  providedIn: 'root'
})
export class ClickService {

  public clickSubject: Subject<any> = new Subject<any>();
  public clickedOnTariffOption$: BehaviorSubject<TariffOptionModel> = new BehaviorSubject<TariffOptionModel>(null);
  public radioButtonCanBeDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public validateIbanByTpiFailed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  constructor() { }
}
