import { Action } from '@ngrx/store';
import { ActivationErrorTypes } from '../models/activation-errors.model';
import { BankAccountDataModel } from '../models/bank-account-data.model';
import { CustomerDataModel } from '../models/customer-data.model';
import { IdDocumentDataModel, IdentificationCustTypes } from '../models/id-document.model';
import { PropertyConsentModel } from '../models/property-consent.model';
import { SimDataModel } from '../models/sim-data.model';
import { TariffDataModel, TariffOptionSelectionStatus } from '../models/tariff-data.model';
import { ActivationState } from '../reducers/activation.reducer';

export enum ActivationActionTypes {
    Activation_Start = '[Activation] Start',
    Activation_Reset = '[Activation] Reset',
    Activation_Finish = '[Activation] Finish',
    Activation_Start_Failure = '[Activation] Start Failure',
    Activation_State_Load = '[ActivationState] Load',
    SimData_Update = '[SimData] Update',
    SimData_Reset = '[SimData] Reset',
    SimData_Reset_Ok = '[SimData] Reset Ok',
    SimData_Reset_Ko = '[SimData] Reset Ko',
    CustomerData_Update = '[CustomerData] Update',
    NoTpiCheck_Update = '[NoTpiCheck] Update',
    TariffData_Load = '[TariffData] Load',
    TariffData_UpdateOptionStatus = '[TariffData] UpdateOptionStatus',
    IdDocumentData_SetDocumentTypes = '[IdDocumentData] SetDocumentTypes',
    BankAccount_Update = '[BankAccount] Update',
    IdentificationTypes_Set = '[IdentificationTypes] Set',
    PropertyConsents_Set = '[PropertyConsents] Set',
    AllPropertyConsents_Set = '[PropertyConsents] AllSet',
    PropertyConsents_Reset = '[PropertyConsents] Reset',
    AckMarketing_Set = '[AckMarketing] Set',
    MnpSelectedProviderId_Set = '[CustomerData] MnpSelectedProviderId_Set',
    AcceptTerms__Update = '[AcceptTerms] AcceptTerms_Update',
    AddressValid_Set = '[AddressValid] AddressValid_Set'
}

export class StartActivation implements Action {
    readonly type = ActivationActionTypes.Activation_Start;
    constructor(public payload: SimDataModel) { }
}

export class ResetActivation implements Action {
    readonly type = ActivationActionTypes.Activation_Reset;
}

export class FinishActivation implements Action {
    readonly type = ActivationActionTypes.Activation_Finish;
    constructor(public payload: string) {}
}
export class LoadActivationState implements Action {
    readonly type = ActivationActionTypes.Activation_State_Load;
    constructor(public payload: ActivationState) { }
}


export class StartActivationFailure implements Action {
    readonly type = ActivationActionTypes.Activation_Start_Failure;
    constructor(public payload: ActivationErrorTypes) { }
}

export class UpdateSimData implements Action {
    readonly type = ActivationActionTypes.SimData_Update;
    constructor(public payload: SimDataModel) { }
}


export class ResetSimData implements Action {
    readonly type = ActivationActionTypes.SimData_Reset;
    constructor(public payload: SimDataModel) { }
}

export class ResetSimDataOk implements Action {
    readonly type = ActivationActionTypes.SimData_Reset_Ok;
}

export class ResetSimDataKo implements Action {
    readonly type = ActivationActionTypes.SimData_Reset_Ko;
}

export class CustomerDataUpdate implements Action {
    readonly type = ActivationActionTypes.CustomerData_Update;
    constructor(public payload: CustomerDataModel) { }
}

export class NoTpiCheckUpdate implements Action {
    readonly type = ActivationActionTypes.NoTpiCheck_Update;
    constructor(public payload: boolean) { }
}

export class TariffDataLoad implements Action {
    readonly type = ActivationActionTypes.TariffData_Load;
    constructor(public payload: TariffDataModel) { }
}

export class UpdateTariffOptionStatus implements Action {
    readonly type = ActivationActionTypes.TariffData_UpdateOptionStatus;
    constructor(public payload: TariffOptionSelectionStatus) { }
}

export class SetDocumentTypes implements Action {
    readonly type = ActivationActionTypes.IdDocumentData_SetDocumentTypes;
    constructor(public payload: IdDocumentDataModel) { }
}

export class UpdateBankAccountData implements Action {
    readonly type = ActivationActionTypes.BankAccount_Update;
    constructor(public payload: BankAccountDataModel) { }
}

export class SetIdentificationTypes implements Action {
    readonly type = ActivationActionTypes.IdentificationTypes_Set;
    constructor(public payload: IdentificationCustTypes) { }
}

export class SetPropertyConsents implements Action {
    readonly type = ActivationActionTypes.PropertyConsents_Set;
    constructor(public payload: { [name: string]: PropertyConsentModel; }[]) { }
}

export class SetAllPropertyConsents implements Action {
    readonly type = ActivationActionTypes.AllPropertyConsents_Set;
}

export class ResetPropertyConsents implements Action {
    readonly type = ActivationActionTypes.PropertyConsents_Reset;
}

export class SetAckMarketing implements Action {
    readonly type = ActivationActionTypes.AckMarketing_Set;
    constructor(public payload: boolean) { }
}

export class SetMnpSelectedProviderId implements Action {
    readonly type = ActivationActionTypes.MnpSelectedProviderId_Set;
    constructor(public payload: number) { }
}

export class UpdateAcceptTerms implements Action {
    readonly type = ActivationActionTypes.AcceptTerms__Update;
    constructor(public payload: boolean) { }
}

export class SetAddressValidation implements Action {
    readonly type = ActivationActionTypes.AddressValid_Set;
    constructor(public payload: boolean) { }
}

export type ActivationActions =
    | StartActivation
    | ResetActivation
    | FinishActivation
    | StartActivationFailure
    | LoadActivationState
    | UpdateSimData
    | ResetSimData
    | ResetSimDataKo
    | ResetSimDataOk
    | CustomerDataUpdate
    | NoTpiCheckUpdate
    | TariffDataLoad
    | UpdateTariffOptionStatus
    | SetDocumentTypes
    | UpdateBankAccountData
    | SetIdentificationTypes
    | SetPropertyConsents
    | SetAllPropertyConsents
    | ResetPropertyConsents
    | SetAckMarketing
    | SetMnpSelectedProviderId
    | UpdateAcceptTerms
    | SetAddressValidation;
