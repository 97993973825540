import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@mvneco/eb-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IAppSettingsResponseDto } from '../../../shared/api-types';
import * as authActions from '../../auth/store/actions/auth.actions';
import * as fromAppSettingsConfigActions from '../store/actions/app-settings-config.actions';
import * as fromAppSettingsConfigStore from '../store/reducers/app-settings-config.reducer';

/**
 * loading app settings from protal layer and implements
 * reaction/effects on key user session lifecycle events (LoginSuccess, LoginFailure, LogoutConfirmed)
 */
@Injectable({
  providedIn: 'root'
})
export class AppSettingsConfigLoaderService {

  constructor(
    private httpClient: HttpClient,
    private actions$: Actions,
    private store: Store<fromAppSettingsConfigStore.AppSettingsConfigState>,
  ) {
    if (!isNullOrUndefined(this.actions$) && !isNullOrUndefined(this.store)) {
      // react on login success
      this.actions$.pipe(
        ofType(authActions.AuthActionTypes.LoginSuccess),
        tap(() => this.store.dispatch(fromAppSettingsConfigActions.loadAppSettingsConfig()))
      ).subscribe();
      // react on login falure
      this.actions$.pipe(
        ofType(authActions.AuthActionTypes.LoginFailure),
        tap(() => this.store.dispatch(fromAppSettingsConfigActions.deleteAppSettingsConfig()))
      ).subscribe();
      // react on logout
      this.actions$.pipe(
        ofType(authActions.AuthActionTypes.LogoutConfirmed),
        tap(() => this.store.dispatch(fromAppSettingsConfigActions.deleteAppSettingsConfig()))
      ).subscribe();
    }
  }

  public getAppSettingsConfig() {
    return this.httpClient.get<IAppSettingsResponseDto>('services/V10/app-settings').pipe(
      map((res) => res),
      catchError(() => of({
        salesPartnerClassification: [],
        invalidCharactersConfig: null,
        housenoAllowedCharacters: null,
        streetnameAllowedCharacters: null,
        properties: {},
        customerProperties: {},
        clientVersion: null,
        earlyEccWhitelistEmails: [],
        blacklistNames: [],
        blacklistMSISDN: [],
        whiteListCharacters: null
      })),
      // tap (res => console.log (res))
    );
  }
}
