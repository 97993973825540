import { createReducer, on } from '@ngrx/store';
import * as fromAppSettingsConfigActions from '../actions/app-settings-config.actions';
import { IAppSettingsInvalidCharactersItem } from '../models/app-settings-invalid-characters-item.model';


export interface AppSettingsConfigState {

  invalidCharactersConfig?: IAppSettingsInvalidCharactersItem;
  streetnameAllowedCharacters?: string;
  housenoAllowedCharacters?: string;
  choosePaymentMethod?: boolean;
  EeccIsActive?: boolean;
  showBankdataEntry?: boolean;
  demoButton?: boolean;
  show3rdSalutation?: boolean; // EB-18006
  showNewsletterSection?: boolean; // EB-19199
  enableAddressCheckOverruling?: boolean;
  earlyEccUrl?: string;
  earlyEccWhitelistEmails?: string[];
  properties?: { [key: string]: {provide: boolean, value: boolean}};
  customerProperties?: { [key: string]: {provide: boolean, value: boolean}};
  clientVersion?: string;
  blacklistNames?: string[];
  blacklistMSISDN?: string[];
  whiteListCharacters?: string;
}


export const initialAppSettingsConfigState: AppSettingsConfigState = {
  invalidCharactersConfig: null,
  choosePaymentMethod: null,
  EeccIsActive: null,
  showBankdataEntry: null,
  demoButton: null,
  show3rdSalutation: null, // EB-18006
  showNewsletterSection: null, // EB-19199
  enableAddressCheckOverruling: null,
  earlyEccUrl: null,
  earlyEccWhitelistEmails: [],
  properties: {},
  customerProperties: {},
  clientVersion: null,
  blacklistNames: [],
  blacklistMSISDN: [],
  whiteListCharacters: null
};

export const reducer = createReducer(
  initialAppSettingsConfigState,
  on(fromAppSettingsConfigActions.setAppSettingsConfig, (state, { data }) => ({
    ...data
  })),
  on(fromAppSettingsConfigActions.deleteAppSettingsConfig, state => ({
    ...initialAppSettingsConfigState
  }))
);
