// tslint:disable: variable-name
import { Injectable } from '@angular/core';
import { Credentials } from '../store/models/credentials.model';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { UserModel, AuthPermissionsMapType } from '../store/models/user.model';
import { map, delay, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IPropertiesTypeDto, IResourceGetSim2ResponseCustomerOptionsOptionsAnonymDto, IUserModelDto, UserModelDto } from '../../../shared/api-types';
import { isNullOrUndefined } from '@mvneco/eb-core';
import { isEmptyInputValue } from '../../../shared/value-validators';
import { UserChangePasswordResult } from '../store/models/user-change-password.model';
import { UserLostPasswordResult } from '../store/models/user-lost-password.model';


export class ServiceError extends Error {
  public ecobillErrCode: number;
  constructor(message: string, ecobillErrCode?: number) {
      // Calling parent constructor of base Error class.
      super(message);
      this.ecobillErrCode = ecobillErrCode;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public userPerms$: BehaviorSubject<AuthPermissionsMapType> = new BehaviorSubject<AuthPermissionsMapType>({});
  public customerOptions$: BehaviorSubject<IResourceGetSim2ResponseCustomerOptionsOptionsAnonymDto> =
   new BehaviorSubject<IResourceGetSim2ResponseCustomerOptionsOptionsAnonymDto>(null);
  constructor(
    private httpClient: HttpClient,
  ) { }

  public login(credentials: Credentials): Observable<UserModel> {
    // console.log('Inside AuthService.login()');
    return this.httpClient.post<CustomUserModelDto>('services/V10/auth/connection', credentials).pipe(
      // tap(res => console.log('login result:', res)),
      delay(0),
      map(res => transform_UserModelDto_to_UserModel(res)),
      tap(res => {
        if (!isNullOrUndefined(res) && !isNullOrUndefined(res.customer) && !isNullOrUndefined(res.customer.options))
        {
          this.customerOptions$.next(res.customer.options);
        }
      }),
      tap(res => { this.userPerms$.next(res.userPerms); })
    );
  }

  public getLoggedUser(): Observable<UserModel> {
    return this.httpClient.get<CustomUserModelDto>('services/V10/auth/connection').pipe(
      map(res => transform_UserModelDto_to_UserModel(res)),
      tap(res => {
        if (!isNullOrUndefined(res) && !isNullOrUndefined(res.customer) && !isNullOrUndefined(res.customer.options))
        {
          this.customerOptions$.next(res.customer.options);
        }
      }),
      tap(res => { this.userPerms$.next(res.userPerms); })
    );
  }

  public logout(): Observable<any> {
    return this.httpClient.delete<any>('services/V10/auth/connection');
  }

  public userChangePassword(passwordOld: string, passwordNew: string): Observable<UserChangePasswordResult | null> {
    if (!isEmptyInputValue(passwordOld) && !isEmptyInputValue(passwordNew)) {
      // UserChangePasswordResult or in api-types.ts?
      return this.httpClient.post<UserChangePasswordResult>('services/V10/ecobill-service-service/userChangePassword',
        {
          oldPassword: passwordOld,
          newPassword: passwordNew
        });
    } else {
      return of(null);
    }
  }

  public userLostPassword(username: string): Observable<UserLostPasswordResult | null> {
    if (!isEmptyInputValue(username)) {
      return this.httpClient.post<UserLostPasswordResult>('services/V10/ecobill-service-service/userLostPassword', { username });
    } else {
      return of(null);
    }
  }
}

export function transform_UserModelDto_to_UserModel(req: CustomUserModelDto): UserModel {
  const permsMap: AuthPermissionsMapType = {};
  if (!isNullOrUndefined(req.perms)) {
    req.perms.forEach(perm => {
      const permission = perm.permission;
      const value = !isNullOrUndefined(perm.value) ? perm.value : false;
      permsMap[permission] = value;
    });
  }
  // console.log('req.customer', req.customer);
  return {
    sub: req.sub,
    subStatus: req.status,
    roleName: req.roleName,
    userPerms: permsMap,
    resource: !isNullOrUndefined(req.resource) ? req.resource : null,
    customer: !isNullOrUndefined(req.customer) ? req.customer : null,
    tpiAvailable: req.tpiAvailable
  };
}


export interface CustomUserModelDto extends IUserModelDto {
  readonly properties: IPropertiesTypeDto;
}
