import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AppSettingsConfigLoaderService } from '../../services/app-settings-config-loader.service';
import * as fromAppSettingsConfigActions from '../actions/app-settings-config.actions';
import * as fromAppSettingsConfigStore from '../reducers/app-settings-config.reducer';

/**
 * effects for app settings config
 */
@Injectable()
export class AppSettingsConfigEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromAppSettingsConfigStore.AppSettingsConfigState>,
    private appSettingsConfigLoaderService: AppSettingsConfigLoaderService
  ) { }

  /**
   * effect loading configuration data from BE using
   * appSettingsConfigLoaderService
   */
  loadAppSettingsConfigEffect$ = createEffect(() => this.actions$.pipe(
    ofType('[AppSettingsConfig] Load AppSettingsConfig'),
    mergeMap(() => this.appSettingsConfigLoaderService.getAppSettingsConfig()
      .pipe(
        map(res => {
          this.store.dispatch(fromAppSettingsConfigActions.setAppSettingsConfig({
            data: {
              ...res
          }}));
          return ({
            type: '[AppSettingsConfig] Load AppSettingsConfig Success', payload: {
              data: {
                ...res
              }
            }
          });
        }),
        catchError((err) => {
          this.store.dispatch(fromAppSettingsConfigActions.setAppSettingsConfig({
            data: {
              invalidCharactersConfig: fromAppSettingsConfigStore.initialAppSettingsConfigState.invalidCharactersConfig,
              streetnameAllowedCharacters: fromAppSettingsConfigStore.initialAppSettingsConfigState.streetnameAllowedCharacters,
              housenoAllowedCharacters: fromAppSettingsConfigStore.initialAppSettingsConfigState.housenoAllowedCharacters,
              properties: fromAppSettingsConfigStore.initialAppSettingsConfigState.properties,
              customerProperties: fromAppSettingsConfigStore.initialAppSettingsConfigState.customerProperties,
              clientVersion: fromAppSettingsConfigStore.initialAppSettingsConfigState.clientVersion,
              blacklistNames: fromAppSettingsConfigStore.initialAppSettingsConfigState.blacklistNames,
              blacklistMSISDN: fromAppSettingsConfigStore.initialAppSettingsConfigState.blacklistMSISDN,
              whiteListCharacters: fromAppSettingsConfigStore.initialAppSettingsConfigState.whiteListCharacters
            }
          }));
          return of({
            type: '[AppSettingsConfig] Load AppSettingsConfig Failure', payload: {
              data: {
                invalidCharactersConfig: undefined,
                streetnameAllowedCharacters: undefined,
                housenoAllowedCharacters: undefined,
                properties: {},
                customerProperties: {},
                clientVersion: undefined,
                blacklistNames: [],
                blacklistMNSISDN: [],
                whiteListCharacters: undefined
              }, error: err
            }
          });
        })
      ))
  ));
}
