/**
 * Never edit this file manually. It is generated as part of the building process
 */
export const AppInfo = {
    name: 'medion-alditalk-client',
    appName: 'medion-alditalk-client',
    version: '1.26.0' ,
    defaultLocale: 'de',
    di18nMode: 'TRANSLATE',
    git: {
        shortHash: 'ca74eb0',
        hash: 'ca74eb0e6c3b7e7927f69372ab198cf952c3ee76',
        subject: 'Merge branch \'next\' into \'master\'',
        sanitizedSubject: 'Merge-branch-next-into-master',
        authoredOn: '1713880407',
        committedOn: '1713880407',
        branch: 'HEAD',
    }
};

